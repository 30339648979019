
import { BIconDownload } from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import QrCode from '~/components/PaymentWaitingPay/QrCode.vue'
import SCBDirectDebit from '~/components/PaymentWaitingPay/SCBDirectDebit.vue'
import PaymentType from '~/components/Payment/PaymentType.vue'
import PaymentAll from '~/components/PaymentMethod/PaymentAll.vue'
import CreditDebit2C2P from '~/components/PaymentMethod/CreditDebit2C2P.vue'
import emailModal from '~/components/modal/email.vue'
const nowDT = new Date()

export default {
  middleware: [
    'auth'
  ],
  components: {
    BIconDownload,
    // BIconEyeFill,
    // BIconPlusSquareFill,
    // BIconDashSquareFill,
    QrCode,
    SCBDirectDebit,
    PaymentType,
    PaymentAll,
    CreditDebit2C2P,
    emailModal
  },
  async asyncData ({ params, $axios, app, store, query, $router, $route }) {
    const orderId = params.orderId
    try {
      const order = await $axios.$get('orders/' + orderId)
      //   console.log('[-] order', orderId, order)
      const createdYear = new Date(order.data.created_at).getFullYear()
      let mappingInputs = {}

      if (order.data.products[0].mapping_input_id !== null) {
        mappingInputs = await $axios.$get('mapping_inputs', {
          params: { mapping_pattern_id: order.data.products[0].mapping_input_id }
        })
        mappingInputs = mappingInputs.data
      }

      const { errorMessage, fail } = query
      //   console.log('debug paramssss', query, errorMessage)

      const orderActivity = await $axios.$post('order/' + orderId + '/activity')
      //   const dayDiff = (new Date() - new Date(order.data.created_at)) / (1000 * 60 * 60 * 24)
      return {
        fail,
        errorMessage,
        uuid: order.data.design_uuid,
        orderId,
        mappingInputs,
        order: order.data,
        packagingTypeId: ((order.data) ? order.data.products[0].packaging_type_id : 1),
        orderActivity: ((orderActivity.success) ? orderActivity.data : []),
        remark: order.data.remark,
        createdYear,
        // priceIsExpire: (dayDiff > 7 && order.data.order_status === 1)
        priceIsExpire: order.data && order.data.order_status === 6
      }
    } catch (error) {
      console.log('catchhhhhh', error.message)
      return {
        uuid: '',
        orderId: 0,
        mappingInputs: 0,
        order: null,
        packagingTypeId: 1
      }
    }
  },
  data () {
    return {
      loadingLastDesign: false,
      w: 0,
      l: 0,
      h: 0,
      promoCodeTxt: '',
      mappingInputId: 0,
      designData: {},
      standardSizeCode: null,
      iframeHeight: '800px',
      fileName: '',
      privewImageName: '',
      slipFileNameOne: '',
      slipTransferDateOne: nowDT.getFullYear() + '-' + ('0' + (nowDT.getMonth() + 1)).slice(-2) + '-' + ('0' + nowDT.getDate()).slice(-2),
      slipTransferTimeOne: nowDT.getHours() + ':' + ('0' + (nowDT.getMinutes())).slice(-2) + ':00',
      slipAmountOne: null,
      slipDataOne: null,
      slipFileNameTwo: '',
      slipTransferDateTwo: null,
      slipTransferTimeTwo: null,
      slipAmountTwo: null,
      slipDataTwo: null,
      slipFileNameThree: '',
      slipTransferDateThree: null,
      slipTransferTimeThree: null,
      slipAmountThree: null,
      slipDataThree: null,
      slipFileNameFour: '',
      slipTransferDateFour: null,
      slipTransferTimeFour: null,
      slipAmountFour: null,
      slipDataFour: null,
      slipFileNameFive: '',
      slipTransferDateFive: null,
      slipTransferTimeFive: null,
      slipAmountFive: null,
      slipDataFive: null,
      slipUploadOne: [],
      slipUploadTwo: [],
      slipUploadThree: [],
      slipUploadFour: [],
      slipUploadFive: [],
      slipLimitFiles: 1,
      isSending: false,
      isSlipSending: false,
      order: [],
      graphic_file: '',
      graphicButtonIsReady: false,
      slipFileOne: '',
      slipFileTwo: '',
      slipFileThree: '',
      slipFileFour: '',
      slipFileFive: '',
      drawingData: '',
      drawingPNG: '',
      scb: require('~/static/images/SCB.jpg'),
      qrcode: require('~/static/images/qrcode.jpg'),
      reProduction_zipcode: '',
      reProduction_amount: 0,
      remark: '',
      csData: null,
      rejectReason: '',
      rejectTemplateReason: '',
      colorText: {
        1: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        2: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        3: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 1 สี',
          4: 'พิมพ์ 4 สี เสมือนจริง'
        },
        5: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        }
      },
      checkLoad: 1,
      socket: null,
      errorMessage: null,
      fail: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    noPrint () {
      return ((this.csData && this.csData[0] && 'FrontColorAmount' in this.csData[0] && this.csData[0].FrontColorAmount === 0) || (this.csData && 'print_front' in this.csData && this.csData.print_front.check === 0))
    }
  },
  beforeDestroy () {
    if (this.$echo && typeof this.$echo.leaveChannel === 'function') {
      this.$echo.leaveChannel('locopack-payment-success')
      this.$echo.disconnect()
    }
  },
  async mounted () {
    // Import the plugin dynamically
    const createEcho = (await import('~/plugins/pusher-echo.js')).default

    // Create an Echo instance
    this.$echo = createEcho()

    this.prePurchase()
    // if (localStorage.getItem('errorMessage')) {
    //   const reason = localStorage.getItem('errorMessage')
    //   Swal.fire({
    //     text: reason,
    //     icon: 'warning'
    //   }).then(() => {
    //     localStorage.removeItem('errorMessage')
    //   })
    // }
    // Dynamic import of the plugin

    this.$echo.channel('locopack-payment-success')
      .on('payment-success', ({ orderId }) => {
        if (orderId && parseInt(orderId) === parseInt(this.$route.params.orderId)) {
          this.$router.push('/payment-success/' + orderId)
        }
      })

    if (!this.isAuthenticated) {
      Swal.fire({
        title: 'กรุณาเข้าสู่ระบบก่อนดูออเดอร์',
        icon: 'warning'
      })
      localStorage.setItem('back_url', this.$route.path)
      this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      return
    }

    this.packagingTypeId = (this.mappingInputs.mappingInput) ? this.mappingInputs.mappingInput.packaging_type_id : this.packagingTypeId
    this.checkLoad = 1
    // this.hideLoading()

    // console.log('orderrrrr !!!!', this.order)
    if (this.order === null) {
      // Swal.fire({
      //   title: 'รหัสผู้ใช้งานไม่สามารถดูออเดอร์นี้ได้',
      //   icon: 'warning'
      // })
    } else {
      window.objCurrentPage = this
      window.closeDesignNew = function () {
        window.objCurrentPage.$bvModal.hide('modalDesigner')
        return true
      }
      window.closeDesign = function () {
        window.objCurrentPage.$bvModal.hide('modalDesigner')
        return true
      }
      window.hideLoadingFromIframe = function () {
        window.objCurrentPage.hideLoading()
        return true
      }
      window.showLoadingFromIframe = function () {
        window.objCurrentPage.showLoading()
        return true
      }
      window.closeAndSaveDesignNew = function (uuid, saveOnly) {
        window.objCurrentPage.uuid = uuid
        window.objCurrentPage.$axios.$post(`order/${window.objCurrentPage.orderId}/set-uuid`, { uuid }).then((response) => {
          window.objCurrentPage.getLastDesign()
          window.objCurrentPage.$axios.get(`orders/${window.objCurrentPage.orderId}`).then((response) => {
            window.objCurrentPage.order = response.data.data
          })
        })
        if (saveOnly === false) {
          window.objCurrentPage.$bvModal.hide('modalDesigner')
        }
        return true
      }
      window.closeAndSaveDesign = function (data) {
        window.objCurrentPage.$bvModal.hide('modalDesigner')
        window.objCurrentPage.setDrawingData(data)
        return true
      }
      window.savePngFromDesigner = function (data) {
        window.objCurrentPage.setDrawingPNG(data)
        return true
      }
      window.returnDrawingData = function () {
        return window.objCurrentPage.drawingData
      }
      const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.iframeHeight = h// - 5
      this.getGraphicStatus()
      if (this.order.is_brief_design === 0 && this.order.design_by_yourself === 1) {
        this.getLastDesign()
      }
      this.csData = (this.order.products && this.order.products[0]) ? JSON.parse(this.order.products[0].option_json) : null
      if (this.csData) {
        if (this.csData && 'summary' in this.csData) {
          this.csData = this.csData.summary.cs_data
        } else {
          this.csData = this.csData.cs_data
        }
      }
      this.reProduction_zipcode = this.order.zipcode
      this.reProduction_amount = this.order.products[0].amount
      if (this.order.payment_status > 1) {
        const slipName = ['One', 'Two', 'Three', 'Four', 'Five']
        let i = 0
        for (const slip of this.order.payment_slip) {
          this['slipFileName' + slipName[i]] = slip.path
          if (this.order.payment_slip_style === 2) {
            const slipDatetime = slip.transfer_datetime.split(' ')
            this['slipTransferDate' + slipName[i]] = slipDatetime[0]
            this['slipTransferTime' + slipName[i]] = slipDatetime[1]
            this['slipAmount' + slipName[i]] = (slip.amount)
          } else {
            this['slipTransferDate' + slipName[i]] = null
            this['slipTransferTime' + slipName[i]] = null
            this['slipAmount' + slipName[i]] = null
          }
          this['slipData' + slipName[i]] = slip.id ? slip.id : null
          i++
        }
      } else {
        this.slipAmountOne = this.$formatPrice(this.order.total)
      }
      if (this.order.payment_slip.length > 0) {
        this.slipLimitFiles = this.order.payment_slip.length
      }
      this.checkLoad = 0
      localStorage.removeItem('click-login-from-page-query')
      localStorage.removeItem('click-login-from-page-params')
      localStorage.removeItem('click-login-from-page')

      if (this.order.new_alert_status === 1) {
        this.$axios.$put(`clear-new-alert/${this.orderId}`).then((res) => {
          if (res.success) {
            this.$store.commit('setNewAlertCount', res.data)
          }
        })
      }
    }

    // console.log('***** hideLoading *****')
    this.hideLoading()
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine', 'setErrorMessage']),
    fileBaseName (url) {
      if (url) {
        const path = require('path')
        return path.basename(url)
      } else {
        return ''
      }
    },
    async updateActivity () {
      const orderActivity = await this.$axios.$post('order/' + this.orderId + '/activity')
      this.orderActivity = ((orderActivity.success) ? orderActivity.data : [])
    },
    async reProduction () {
      // concern promocode remove
      // const smeJson = JSON.parse(this.order.products[0].sme_json)
      // const patternsData = []
      // for (const k in this.order.products_all) {
      //   const smeJsonTmp = JSON.parse(this.order.products_all[k].product.sme_json)
      //   patternsData.push({
      //     color: smeJsonTmp.color,
      //     enamel_id: (smeJsonTmp.enamel_id),
      //     packaging_type_id: smeJsonTmp.packaging_type_id,
      //     paper_gsm: smeJsonTmp.paper_gsm,
      //     paper_type_id: smeJsonTmp.paper_type_id,
      //     pattern_id: smeJsonTmp.pattern_id,
      //     special_technic_id: smeJsonTmp.special_technic_id,
      //     sub_id: (smeJsonTmp.sub_id) ? smeJsonTmp.sub_id : 0
      //   })
      // }
      // const postData = {
      //   option_detail: this.order.option_detail,
      //   detail_text: (this.order.detail_text !== '' && this.order.detail_text !== null) ? (this.order.detail_text).replace(this.order.amount.toLocaleString() + ' ชิ้น', this.reProduction_amount.toLocaleString() + ' ชิ้น') : '',
      //   amount: this.reProduction_amount,
      //   price_per_pcs: Number(this.order.price_per_pcs),
      //   print_plate_price: Number(this.order.print_plate_price),
      //   plate_diecut_price: Number(this.order.plate_diecut_price),
      //   shipping_price: Number(this.order.shipping_price),
      //   require_date: this.order.require_date,
      //   product: {
      //     amount: this.reProduction_amount,
      //     cardPaperActive: smeJson.cardPaperActive,
      //     cardPaperActiveName: smeJson.cardPaperActiveName,
      //     color: smeJson.color,
      //     deliveryDate: smeJson.deliveryDate,
      //     design_by_yourself: smeJson.design_by_yourself,
      //     drawingData: '',
      //     drawingPNG: '',
      //     h: smeJson.h,
      //     is_brief_design: smeJson.is_brief_design,
      //     l: smeJson.l,
      //     mapping_inputs: smeJson.mapping_inputs,
      //     mapping_pattern_id: (smeJson.mapping_pattern_id) ? smeJson.mapping_pattern_id : this.order.products[0].mapping_input_id,
      //     paperGramSelected: smeJson.paperGramSelected,
      //     paper_type_id: smeJson.paper_type_id,
      //     promotion_code: this.promoCodeTxt,
      //     sample_product_categories_id: smeJson.sample_product_categories_id,
      //     totalResult: smeJson.totalResult,
      //     type: 0,
      //     w: smeJson.w,
      //     zipcode: this.reProduction_zipcode,
      //     patterns: patternsData
      //   },
      //   factory_id: this.order.products[0].factory_id,
      //   print_type_id: this.order.products[0].print_type_id,
      //   is_brief_design: smeJson.is_brief_design,
      //   design_by_yourself: smeJson.design_by_yourself,
      //   zipcode: this.reProduction_zipcode,
      //   req_quotation_click: 0,
      //   uuid: '',
      //   reproduction: 1,
      //   reorder_order_id: (this.priceIsExpire) ? null : this.order.id
      // }
      const postData = {
        amount: this.reProduction_amount,
        zipcode: this.reProduction_zipcode
        // promotion_code: this.promoCodeTxt
      }
      this.showLoading()
      const result = await this.$axios.$post(`order/${this.order.id}/re-production`, postData)
      if (result.status === true) {
        const parameters = {
          order_code: result.data.order_code,
          id: result.data.order_id
        }
        this.$router.push(this.localeLocation({ name: 'checkout-id', params: parameters }))
      } else {
        this.hideLoading()
        this.setAlertWithLine({
          show: true,
          message: 'เกิดข้อผิดพลาด ในการสั่งซื้อ',
          header: 'แจ้งเตือน'
        })
      }
    },
    sendDataLayer () {
      if (localStorage.getItem('bank-transfer-finished-send-' + this.orderId) === this.orderId) {
        return false
      }
      localStorage.setItem('bank-transfer-finished-send-' + this.orderId, this.orderId)
      try {
        const pkgType = (this.mappingInputs.mappingInput) ? this.mappingInputs.mappingInput.packaging_type_id : this.packagingTypeId
        const csData = JSON.parse(this.order.products[0].option_json)
        const smeData = JSON.parse(this.order.products[0].sme_json)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        let dimension2 = ''
        if (this.order.is_brief_design === 1) {
          dimension2 = 'จ้างออกแบบ'
        } else if (this.order.design_by_yourself === 1) {
          dimension2 = 'ออกแบบออนไลน์'
        } else {
          dimension2 = 'ออกแบบเอง'
        }
        let dimension4 = ''
        const productData = this.order.products_all[0].product
        if (productData.h) {
          dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
        } else {
          dimension4 = `${productData.w / 10} x ${productData.l / 10}`
        }
        const paymentMethod = 'โอนเงิน'
        const withVat = this.order.total * (1 + (7 / 100))
        const vatPrice = withVat - this.order.total
        let totalWithoutDiscount = this.order.total_without_discount
        if (this.order.total_without_discount === null || typeof this.order.total_without_discount === 'undefined') {
          totalWithoutDiscount = this.order.total
        }

        this.cardPaperActiveName = (smeData && 'cardPaperActiveName' in smeData) ? smeData.cardPaperActiveName : ''
        let gsm = ''
        let zipcode = ''
        if (csData) {
          if ('cs_data' in csData) {
            gsm = (('gsm' in csData.cs_data) ? csData.cs_data.gsm : csData.cs_data[0].paper_gsm + '')
            zipcode = (('zipcode' in csData.cs_data) ? csData.cs_data.zipcode : csData.cs_data[0].zipcode)
          } else if ('summary' in csData) {
            gsm = (('cs_data' in csData.summary) ? csData.summary.cs_data[0].paper_gsm : '')
            zipcode = (('cs_data' in csData.summary) ? csData.summary.cs_data[0].zipcode : '')
          }
        }
        let productCode = ''
        if (this.order.products[0].mapping_input_id) {
          productCode = `LOCOPACK_${pkgType}` + this.order.products[0].mapping_input_id.toString().padStart(9, '0')
        } else if (this.order.products[0].rigid_id) {
          productCode = `LOCOPACK_${pkgType}` + this.order.products[0].rigid_id.toString().padStart(9, '0')
        }
        window.dataLayer.push({
          event: 'purchase',
          paymentMethod,
          discount: totalWithoutDiscount - this.order.total,
          mold: this.order.print_plate_price,
          dieCut: this.order.plate_diecut_price,
          moldCost: this.order.print_plate_price,
          dieCutCost: this.order.plate_diecut_price,
          creditCardFee: ((parseInt(this.order.trans_2c2p_creditcard_id) > 0 || parseInt(this.order.trans_2c2p_online_id) > 0) ? this.calCostFor2C2P(this.order.total, this.order.products[0].amount) : 0),
          designFee: this.order.sum_design_price,
          shippingCost: this.order.shipping_price,
          ecommerce: {
            purchase: {
              actionField: {
                id: this.order.order_code,
                revenue: parseFloat(this.order.total).toFixed(2),
                tax: parseFloat(vatPrice).toFixed(2),
                shipping: 0, // this.order.shipping_price,
                coupon: ((smeData && 'promotion_code' in smeData) ? smeData.promotion_code : '')
              },
              products: [{
                id: productCode,
                name: ((smeData && 'mapping_inputs' in smeData) ? smeData.mapping_inputs.name_th : ''),
                price: (typeof this.order.price_per_pcs !== 'undefined' ? this.order.price_per_pcs : ''),
                category: this.$pkgTypeThaiName(pkgType),
                quantity: this.order.amount,
                discount: ((csData && csData.discount) ? csData.discount.toFixed(2) : 0),
                brand: 'Locopack',
                dimension1: (typeof this.order.products[0] !== 'undefined' ? 'โรงงาน ' + this.order.products[0].factory_id : ''),
                dimension2,
                dimension3: this.mappingInputs.mappingInput.name_th,
                dimension4,
                dimension5: this.cardPaperActiveName,
                dimension6: gsm,
                dimension7: zipcode,
                dimension8: ((smeData && 'color' in smeData) ? this.colorText[pkgType][smeData.color] : ''),
                dimension9: (this.order.products[0].plate_text + '').replace('พื้นที่', ''),
                dimension10: '',
                dimension11: '',
                dimension12: this.order.products[0].delivery_by,
                dimension13: this.order.products[0].production_lead_time
              }]
            }
          }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    reProductionOpenModal () {
      this.$bvModal.show('modal-re-production')
    },
    getPatternWLH () {
      let patternId
      let w = 0
      let l = 0
      let h = 0
      if (this.packagingTypeId !== 4) {
        const jsonOption = JSON.parse(this.order.products[0].option_json)
        patternId = this.order.products[0].mapping_input_id
        let csData = {}
        if (jsonOption) {
          if ('cs_data' in jsonOption) {
            csData = jsonOption.cs_data
          } else if ('cs_input' in jsonOption) {
            csData = jsonOption.cs_input
          } else if ('cs_data' in jsonOption) {
            csData = jsonOption.cs_data
          }

          // if (jsonOption.summary) {
          //   if ('summary' in jsonOption && 'cs_data' in jsonOption.summary) {
          //     console.log('****  Case I  ****')
          //     csData = jsonOption.summary.cs_data
          //   } else if ('cs_input' in jsonOption) {
          //     console.log('****  Case II  ****')
          //     csData = jsonOption.cs_input
          //   } else if ('cs_data' in jsonOption) {
          //     console.log('****  Case III   ****')
          //     csData = jsonOption.cs_data
          //   }
          // }

          if (!('cs_input' in jsonOption) && !('cs_input' in jsonOption) && ('array_paper_trim' in jsonOption)) {
            const arrayPaperTrim = jsonOption.array_paper_trim
            w = ('w' in arrayPaperTrim) ? parseInt(arrayPaperTrim.w) : 0
            l = ('l' in arrayPaperTrim) ? parseInt(arrayPaperTrim.l) : 0
            h = ('h' in arrayPaperTrim) ? parseInt(arrayPaperTrim.h) : 0
          }
        }

        if (typeof csData === 'undefined') {
          return false
        }

        if (typeof csData[0] !== 'undefined') {
          w = ('W' in csData[0]) ? csData[0].W : csData[0].w
          l = ('L' in csData[0]) ? csData[0].L : csData[0].l
          h = ('D' in csData[0]) ? csData[0].D : csData[0].d
        } else if (typeof csData !== 'undefined') {
          w = csData.w / 10
          l = csData.l / 10
          h = ('D' in csData) ? csData.D / 10 : csData.d / 10
        }
      } else {
        w = this.order.products[0].rigid.sticker_front_l
        l = this.order.products[0].rigid.sticker_front_w
        patternId = this.$getPatternName(this.order.products[0].rigid.product_type, this.order.products[0].rigid.locopack_code)
      }
      return [w, l, h, patternId]
    },
    isImageFile (name) {
      if (name) {
        const path = require('path')
        const ext = path.extname(name)
        if (ext === '.jpeg' || ext === '.jpg' || ext === '.png' || ext === '.tiff' || ext === '.gif') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getLastDesign () {
      const _this = this
      this.loadingLastDesign = true
      let parameter = {}
      if (this.uuid == null) {
        const wlhPattern = this.getPatternWLH()
        if (wlhPattern[3] === 'shelf' || wlhPattern[3] === 'table') {
          parameter = {
            w: wlhPattern[0],
            l: wlhPattern[1],
            h: wlhPattern[2],
            patternName: wlhPattern[3],
            'my-drawing': 1,
            'new-order': 1
          }
        } else {
          parameter = {
            w: wlhPattern[0],
            l: wlhPattern[1],
            h: wlhPattern[2],
            pattern_id: wlhPattern[3],
            'my-drawing': 1,
            'new-order': 1
          }
        }
      } else {
        parameter = {
          uuid: this.uuid,
          'my-drawing': 1
        }
      }
      this.$axios.get('designs', { params: parameter }).then((res) => {
        if (res.data && res.data.data.current) {
          this.designData = res.data.data.current
        } else {
          this.designData = {}
        }
        _this.loadingLastDesign = false
      })
    },
    setDrawingData (data) {
      this.drawingData = data
      if (data !== '') {
        this.saveBriefDetail()
      }
    },
    setDrawingPNG (data) {
      this.drawingPNG = data
      if (data !== '') {
        this.saveBriefDetail()
      }
    },
    approveDesign () {
      if (this.order.payment_status === 3) {
        this.isSending = true
        this.$axios.$post(`order/${this.orderId}/approve-design`).then((response) => {
          this.$axios({
            url: this.$baseurl('orders/' + this.orderId),
            method: 'GET'
          }).then((res) => {
            window.location.reload()
          })
        })
      } else {
        this.setAlertWithLine({
          show: true,
          message: 'ไม่สามารถส่งตรวจสอบได้ เนื่องจากการชำระยังไม่อนุมัติ',
          header: 'แจ้งเตือน'
        })
      }
    },
    approveTemplate () {
      if (this.order.payment_status === 3) {
        this.isSending = true
        this.$axios.$post(`order/${this.orderId}/approve-template-status`).then((response) => {
          this.$axios({
            url: this.$baseurl('orders/' + this.orderId),
            method: 'GET'
          }).then((res) => {
            window.location.reload()
          })
        })
      } else {
        this.setAlertWithLine({
          show: true,
          message: 'ไม่สามารถนุมัติ Template ได้เนื่องจากการชำระยังไม่อนุมัติ',
          header: 'แจ้งเตือน'
        })
      }
    },
    approveStartProduction (isRejectBefore) {
      this.isRejectBefore = isRejectBefore
      const pkgTypeId = (this.mappingInputs.mappingInput) ? this.mappingInputs.mappingInput.packaging_type_id : this.packagingTypeId
      if (pkgTypeId === 3) {
        this.$bvModal.show('modalPaperColor')
      } else {
        this.approveStartProductionConfirm()
      }
    },
    approveStartProductionConfirm () {
      const thisObj = this
      thisObj.$bvModal.hide('modalPaperColor')
      this.isSending = true
      this.$axios.$post(`order/${this.orderId}/approve-start-production`, { isRejectBefore: this.isRejectBefore }).then((response) => {
        this.$axios({
          url: this.$baseurl('orders/' + this.orderId),
          method: 'GET'
        }).then((res) => {
          thisObj.isSending = false
          thisObj.order.graphic_file_thumbnail = res.data.data.graphic_file_thumbnail
          thisObj.order.graphic_status = res.data.data.graphic_status
        })
      })
    },
    rejectTemplate () {
      this.isSending = true
      this.$axios.$post(`order/${this.orderId}/user-reject-template`, { rejectTemplateReason: this.rejectTemplateReason }).then((response) => {
        this.$axios({
          url: this.$baseurl('orders/' + this.orderId),
          method: 'GET'
        }).then((res) => {
          this.$bvModal.hide('modal-reject-template')
          window.location.reload()
        })
      })
    },
    rejectGraphic () {
      const thisObj = this
      this.isSending = true
      this.$axios.$post(`order/${this.orderId}/user-reject-graphic`, { rejectReason: this.rejectReason }).then((response) => {
        this.$axios({
          url: this.$baseurl('orders/' + this.orderId),
          method: 'GET'
        }).then((res) => {
          this.isSending = false
          thisObj.order.graphic_file_thumbnail = res.data.data.graphic_file_thumbnail
          thisObj.order.graphic_status = res.data.data.graphic_status
          this.$bvModal.hide('modal-reject-reason')
        })
      })
    },
    getUrlDesigner () {
      let url = ''
      if (this.order && this.order.design_by_yourself === 1) {
        if (this.order && 'products' in this.order && 'option_json' in this.order.products[0] && this.packagingTypeId !== 4) {
          const wlhPattern = this.getPatternWLH()
          let w = wlhPattern[0]
          let l = wlhPattern[1]
          let h = wlhPattern[2]
          const mappingInputId = wlhPattern[3]
          this.mappingInputId = mappingInputId
          for (const k in this.mappingInputs.standard_sizes) {
            const wld = this.mappingInputs.standard_sizes[k]
            if (`${wld.w},${wld.l},${wld.h}` === `${w / 10},${l / 10},${h / 10}`) {
              this.standardSizeCode = wld.code
              if ('w_for_calculate' in wld && wld.w_for_calculate) {
                w = wld.w_for_calculate * 10
              }
              if ('l_for_calculate' in wld && wld.l_for_calculate) {
                l = wld.l_for_calculate * 10
              }
              if ('h_for_calculate' in wld && wld.h_for_calculate) {
                h = wld.h_for_calculate * 10
              }
            }
          }

          url = `/designer?flow=edit&pattern_id=${mappingInputId}&width=${w}&length=${l}&standard_size=${this.standardSizeCode}`

          if (typeof h !== 'undefined' && h !== null) {
            url = url + `&depth=${h}`
          }

          if (mappingInputId === 57 || mappingInputId === 58) {
            const smeJson = JSON.parse(this.order.products[0].sme_json)
            if (smeJson.special_technic_id.length > 0) {
              if (smeJson.special_technic_id[0] === 61) {
                url = url + '&dim=3'
              } else {
                url = url + '&dim=6'
              }
            }
            url = url + '&r=5'
          }

          if (this.uuid !== '' && this.uuid !== null) {
            url = url + '&uuid=' + this.uuid
          }
          if (this.order && this.order.templateFile !== '' && this.order.templateFile !== null) {
            url = url + '&template=' + encodeURIComponent(this.order.templateFile)
          }
          if (this.order && this.order.graphic_file_svg !== '' && this.order.graphic_file_svg !== null) {
            url = url + '&template=' + encodeURIComponent(this.order.graphic_file_svg)
          }
          return url
        } else if (this.order && 'products' in this.order && this.packagingTypeId === 4) {
          const wlhPattern = this.getPatternWLH()
          const w = wlhPattern[0]
          const l = wlhPattern[1]
          const mappingInputId = wlhPattern[3]
          url = '/designer?flow=edit'
          if (mappingInputId === 'shelf' || mappingInputId === 'table') {
            url += `&patternName=${mappingInputId}&width=${w}&length=${l}&depth=0`
          }
          if (this.uuid !== '' && this.uuid !== null) {
            url += '&uuid=' + this.uuid
          }
          return url
        } else {
          return ''
        }
      }
    },
    openModalRejectReason () {
      this.$bvModal.show('modal-reject-reason')
    },
    openModalRejectTemplate () {
      this.$bvModal.show('modal-reject-template')
    },
    openModalDesigner () {
      // this.showLoading() hide because when error stuck in asyncData in ur-design can close tab
      this.$bvModal.show('modalDesigner')
    },
    getGraphicStatus () {
      if (this.order.design_uuid !== '' && this.order.design_uuid !== null) {
        this.graphicButtonIsReady = true
      } else {
        this.$axios({
          url: this.$baseurl(`order/${this.orderId}/get-drawing`),
          method: 'GET'
        }).then((response) => {
          if (response.data.data != null) {
            const buff = Buffer.from(response.data.data, 'base64')
            const re = new RegExp(String.fromCharCode(160), 'g')
            this.drawingData = buff.toString('binary').replace(re, ' ')
          }
          this.graphicButtonIsReady = true
        })
      }
    },
    checkShowDesignButton () {
      if (this.order.design_uuid && this.order.design_by_yourself === 1 && this.order.payment_status === 3) {
        return true
      }
      if ((this.order.design_uuid !== '' && this.order.design_uuid !== null && this.order.design_by_yourself === 1) ||
          ((this.drawingData !== '' || this.drawingPNG !== '') && this.order.graphic_status !== 2) ||
          (this.order.design_by_yourself === 1 && this.order.graphic_status === 0) ||
          (
            this.order.graphic_status !== 2 &&
            this.order.design_by_yourself === 1 &&
            (
              this.standardSizeCode !== null ||
              (('graphic_file_thumbnail' in this.order && this.order.graphic_file_thumbnail !== '') ||
              ('templateFile' in this.order && this.order.templateFile !== '' && this.order.templateFile !== null && this.standardSizeCode === null))
            )
          )
      ) {
        const wlhPattern = this.getPatternWLH()
        const mappingInputId = wlhPattern[3]
        this.mappingInputId = mappingInputId
        if (!this.$checkCanFreeDesign(mappingInputId) && ((this.order.templateFile === null || this.order.templateFile === '') && this.order.graphic_file_svg === '')) {
          return false
        }
        return true
      } else {
        return false
      }
    },
    quotation () {
      this.isSending = true
      this.$axios({
        url: this.$baseurl(`order/${this.orderId}/quotation-pdf`),
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `quotation-${this.orderId}-pdf.pdf`)
        document.body.appendChild(link)
        link.click()
        this.isSending = false
      })
    },
    saveBriefDetail () {
      // set stage to sending
      this.isSending = true
      const thisValue = this
      let params = {}
      // set data formate

      if (this.drawingData !== '' || this.drawingPNG !== '' || (this.standardSizeCode !== '' && this.design_by_yourself === 1)) {
        params = {
          graphic_status: 1,
          drawingData: this.drawingData,
          drawingPNG: this.drawingPNG
        }
      } else {
        params = {
          graphic_status: 1,
          brd_file: this.graphic_file,
          fileName: this.fileName
        }
      }
      // post to API
      this.$axios.$post('order/' + this.orderId + '/save-brief-design', params).then((resp) => {
        // @TODO redirect to target
        if (resp.success === true) {
          thisValue.order.graphic_status = 1
          thisValue.order.graphic_file_thumbnail = resp.data.file_path
          this.fileName = 'แก้ไขไฟล์เรียบร้อย'
        }
        this.isSending = false
      }).catch((error) => {
        this.isSending = false
        this.setAlertWithLine({
          show: true,
          message: error.message,
          header: 'แจ้งเตือน'
        })
      })
    },
    // newLineToBR (txt) {
    //   const doc = txt + ''
    //   return doc.split(new RegExp('\n'))
    // },
    async savePaymentMultiSlip () {
      this.showLoading()
      try {
        this.isSlipSending = true
        const nameSlip = ['One', 'Two', 'Three', 'Four', 'Five'].filter((value, index) => {
          return index < this.slipLimitFiles
        })
        // try {
        let valid = false
        for (const slip of nameSlip) {
          if (!this['slipFileName' + slip].includes('http') && !(this['slipFile' + slip] && this['slipTransferDate' + slip] && this['slipTransferTime' + slip] && this['slipAmount' + slip])) {
            valid = true
          }
        }
        if (valid) {
          this.hideLoading()
          this.setAlert({
            show: true,
            message: 'กรุณาระบุข้อมูลสลิปให้ครบถ้วน',
            header: 'แจ้งเตือน'
          })
        } else {
          for (const slip of nameSlip) {
            if (this['slipFile' + slip] && this['slipTransferDate' + slip] && this['slipTransferTime' + slip] && this['slipAmount' + slip]) {
              if (slip === 'One') {
                this.sendDataLayer()
              }
              const params = {
                payment_status: 2,
                slip_file: this['slipFile' + slip],
                transfer_date: this['slipTransferDate' + slip],
                transfer_time: this['slipTransferTime' + slip],
                amount: this['slipAmount' + slip]
              }
              const res = await this.$axios.$post('order/' + this.orderId + '/save-payment-slip', params)
              this['slipFileName' + slip] = res.data.file_path
              this['slipFile' + slip] = ''
              this['slipData' + slip] = res.data.slip.id
              this.order.payment_status = 2
              // this.order.payment_slip_style = 2
            }
          }
          this.updateActivity()
          this.hideLoading()
        }
        // } catch (err) {
        //   this.setAlert({
        //     show: true,
        //     message: 'เกิดข้อผิดพลาดไม่สามารถบันทึกสลิปได้',
        //     header: 'แจ้งเตือน'
        //   })
        // }
        this.isSlipSending = false
      } catch (err) {
        this.hideLoading()
        this.setAlertWithLine({
          show: true,
          message: 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
          header: 'แจ้งเตือน'
        })
      }
    },
    savePaymentSlip () {
      // set stage to sending
      this.isSlipSending = true
      const thisValue = this

      // set data formate
      const params = {
        payment_status: 2,
        slip_file: this.slip_file
      }

      // post to API
      this.$axios.$post('order/' + this.orderId + '/save-payment-slip', params).then((resp) => {
        // @TODO redirect to target
        if (resp.success === true) {
          thisValue.order.payment_status = 2
        }
      }).catch((error) => {
        this.setAlertWithLine({
          show: true,
          message: error.message,
          header: 'แจ้งเตือน'
        })
      })
      this.isSending = false
    },
    toText (status) {
      let ret = ''
      if (status === 4) {
        ret = 'อยู่ระหว่างจัดส่ง'
      } else if (status === 7) {
        ret = 'อยู่ระหว่างการผลิต'
      } else if (status === 5) {
        ret = 'จัดส่งเรียบร้อย'
      }
      return ret
    },
    paymentStatus (order) {
      let ret = ''

      if (order.payment_status === 1) {
        ret = ' รอการชำระเงิน '
      } else if (order.payment_status === 2) {
        ret = ' กำลังตรวจสอบหลักฐานการชำระเงิน '
      } else if (order.payment_status === 3) {
        ret = ' ชำระเงินแล้ว '
      }

      let paymentType = ''
      if (parseInt(order.trans_2c2p_creditcard_id) > 0) {
        paymentType = 'ผ่านช่องทาง Credit Card'
      } else if (parseInt(order.trans_2c2p_online_id) > 0) {
        paymentType = 'ผ่านช่องทาง Online banking'
      } else {
        paymentType = ''
      }

      return ret + paymentType
    },
    async browseFile (file) {
      this.graphic_file = await this.singleFileToBase64(file.target.files[0])
      this.fileName = file.target.files[0].name
      this.saveBriefDetail()
    },
    async browseSlipFile (file, inputName) {
      this['slipFile' + inputName] = await this.singleFileToBase64(file.target.files[0])
      this['slipFileName' + inputName] = file.target.files[0].name
    },
    showPreviewSlip (name) {
      this.privewImageName = this['slipFile' + name] ? this['slipFile' + name] : this['slipFileName' + name]
      this.$bvModal.show('modalPreviewSlip')
    },
    addSlipLimitFiles () {
      if (this.slipLimitFiles <= 4) {
        this.slipLimitFiles++
      }
    },
    async removeSlipLimitFiles (slipName) {
      let switchStatus = false
      const nameSlip = ['One', 'Two', 'Three', 'Four', 'Five'].filter((value, index) => {
        return index < this.slipLimitFiles
      })

      if (this['slipFileName' + slipName].includes('http')) {
        this.showLoading()
        const slipData = {
          path: this['slipFileName' + slipName].replace(this.$store.state.aws_s3, ''),
          slip_id: this['slipData' + slipName] ? this['slipData' + slipName] : null
        }
        try {
          await this.$axios.$post('order/' + this.orderId + '/remove-payment-slip', slipData)
          this.hideLoading()
        } catch (err) {
          this.setAlertWithLine({
            show: true,
            message: 'เกิดข้อผิดพลาด ไม่สามารถลบสลิปนี้ได้',
            header: 'แจ้งเตือน'
          })
          this.hideLoading()
        }
      }

      let index = 0
      for (const slip of nameSlip) {
        if (slip === slipName) {
          this['slipFileName' + slipName] = ''
          this['slipFile' + slipName] = ''

          this['slipTransferDate' + slipName] = null
          this['slipTransferTime' + slipName] = null
          this['slipAmount' + slipName] = null
          this['slipData' + slipName] = null

          this.$refs['slipUpload' + slipName].reset()
          switchStatus = true
        }
        if (switchStatus === true) {
          if (nameSlip[index + 1] !== undefined) {
            this['slipFileName' + slip] = this['slipFileName' + nameSlip[index + 1]]
            this['slipFile' + slip] = this['slipFile' + nameSlip[index + 1]]
            this['slipUpload' + slip] = this['slipUpload' + nameSlip[index + 1]]
            this.$refs['slipUpload' + slip].files.push(this['slipUpload' + nameSlip[index + 1]])

            this['slipTransferDate' + slip] = this['slipTransferDate' + nameSlip[index + 1]]
            this['slipTransferTime' + slip] = this['slipTransferTime' + nameSlip[index + 1]]
            this['slipAmount' + slip] = this['slipAmount' + nameSlip[index + 1]]
            this['slipData' + slip] = this['slipData' + nameSlip[index + 1]]

            this['slipFileName' + nameSlip[index + 1]] = ''
            this['slipFile' + nameSlip[index + 1]] = ''

            this['slipTransferDate' + nameSlip[index + 1]] = null
            this['slipTransferTime' + nameSlip[index + 1]] = null
            this['slipAmount' + nameSlip[index + 1]] = null
            this['slipData' + nameSlip[index + 1]] = null

            this.$refs['slipUpload' + nameSlip[index + 1]].reset()
          }
        }
        index++
      }
      this.slipLimitFiles--
    },
    singleFileToBase64 (files) {
      const reader = new FileReader()
      // read the file into a base64 format
      reader.readAsDataURL(files)

      // return result base 64
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort()
        }

        // return the base 64 string
        reader.onload = function () {
          resolve(reader.result)
        }
      })
    },

    paid (status) {
      if (status) {
        this.showLoading()
        window.location.reload()
      }
    },
    error (result, status) {
      if (result.error === 'verifyEmail') {
        this.hideLoading()
        this.$refs.emailModal.show = true
        return false
      }
    },
    prePurchase () {
      if (localStorage.getItem('pre-purchase-' + this.orderId) === this.orderId) {
        return false
      }
      localStorage.setItem('pre-purchase-' + this.orderId, this.orderId)
      try {
        this.$axios({
          url: this.$baseurl('orders/' + this.orderId),
          method: 'GET'
        }).then((response) => {
          if (response.status === 200 && response.data.success === true) {
            console.log('prePurchase : start')
            const data = response.data.data
            const pkgType = (data.products[0].packaging_type_id) ? data.products[0].packaging_type_id : data.packaging_type_id
            const csData = JSON.parse(data.products[0].option_json)
            const smeData = JSON.parse(data.products[0].sme_json)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ ecommerce: null })
            let dimension2 = ''
            if (data.is_brief_design === 1) {
              dimension2 = 'จ้างออกแบบ'
            } else if (data.design_by_yourself === 1) {
              dimension2 = 'ออกแบบออนไลน์'
            } else {
              dimension2 = 'ออกแบบเอง'
            }
            let dimension4 = ''
            const productData = data.products_all[0].product
            if (productData.h) {
              dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
            } else {
              dimension4 = `${productData.w / 10} x ${productData.l / 10}`
            }
            const paymentMethod = this.$paymentTypeName(data.payment_type)
            const withVat = data.total * (1 + (7 / 100))
            const vatPrice = withVat - data.total
            let totalWithoutDiscount = data.total_without_discount
            if (data.total_without_discount === null || typeof data.total_without_discount === 'undefined') {
              totalWithoutDiscount = data.total
            }
            const cardPaperActiveName = (smeData && 'cardPaperActiveName' in smeData) ? smeData.cardPaperActiveName : ''
            let gsm = ''
            const zipcode = data.zipcode ? data.zipcode : ''
            if (csData) {
              if ('cs_data' in csData) {
                gsm = (('gsm' in csData.cs_data) ? csData.cs_data.gsm : csData.cs_data[0].paper_gsm + '')
              } else if ('summary' in csData) {
                gsm = (('cs_data' in csData.summary) ? csData.summary.cs_data[0].paper_gsm : '')
              }
            }
            let productCode = ''
            let dimension1 = ''
            let dimension3 = ''
            if (data.products[0].mapping_input_id) {
              productCode = `LOCOPACK_${pkgType}` + data.products[0].mapping_input_id.toString().padStart(9, '0')
              dimension1 = (typeof data.products[0] !== 'undefined' ? 'โรงงาน ' + data.products[0].factory_id : '')
              dimension3 = data.products[0].name
            } else if (data.products[0].rigid_id) {
              productCode = `LOCOPACK_${pkgType}` + data.products[0].rigid_id.toString().padStart(9, '0')
              dimension1 = (typeof data.products[0].rigid.factory_id !== 'undefined' ? 'โรงงาน ' + data.products[0].rigid.factory_id : '')
              dimension3 = data.products[0].rigid.name
            }
            const res = window.dataLayer.push({
              event: 'prePurchase',
              paymentMethod,
              discount: totalWithoutDiscount - data.total,
              mold: data.print_plate_price,
              dieCut: data.plate_diecut_price,
              moldCost: data.print_plate_price,
              dieCutCost: data.plate_diecut_price,
              creditCardFee: ((parseInt(data.trans_2c2p_creditcard_id) > 0 || parseInt(data.trans_2c2p_online_id) > 0) ? this.calCostFor2C2P(data.total, data.products[0].amount) : 0),
              designFee: data.sum_design_price,
              shippingCost: data.shipping_price,
              ecommerce: {
                purchase: {
                  actionField: {
                    id: data.order_code,
                    revenue: parseFloat(data.total).toFixed(2),
                    tax: parseFloat(vatPrice).toFixed(2),
                    shipping: 0, // data.shipping_price,
                    coupon: ((smeData && 'promotion_code' in smeData) ? smeData.promotion_code : '')
                  },
                  products: [{
                    id: productCode,
                    name: ((smeData && 'mapping_inputs' in smeData) ? smeData.mapping_inputs.name_th : ''),
                    price: (typeof data.price_per_pcs !== 'undefined' ? data.price_per_pcs : ''),
                    category: this.$pkgTypeThaiName(pkgType),
                    quantity: data.amount,
                    discount: ((csData && csData.discount) ? csData.discount.toFixed(2) : 0),
                    brand: 'Locopack',
                    dimension1,
                    dimension2,
                    dimension3,
                    dimension4,
                    dimension5: cardPaperActiveName,
                    dimension6: gsm,
                    dimension7: zipcode,
                    dimension8: ((smeData && 'color' in smeData) ? this.colorText[pkgType][smeData.color] : ''),
                    dimension9: (data.products[0].plate_text + '').replace('พื้นที่', ''),
                    dimension10: '',
                    dimension11: '',
                    dimension12: data.products[0].delivery_by,
                    dimension13: data.products[0].production_lead_time
                  }]
                }
              }
            })
            if (res) {
              console.log('Purchase done')
            } else {
              console.log('Purchase failed')
            }
          }
        }).catch(function (error) {
          return { error: true, message: error.message }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }
}
